<template>
  <div class="home">
    <div class="container" style="max-width:768px;">
        <div class="row justify-content-center py-4 py-md-5 px-0">
            <div class="col text-center">
                <a href="https://signup.barkbuildings.com"><img src="../assets/logo.svg"/></a>
            </div>
            <div class="col" v-if="step < threshold">
                <div class="d-flex justify-content-end align-self-center ">
                    <div class="d-flex" v-for="n in threshold">
                      <div class="d-flex flex-grow-1">
                        <div class="text-center">
                            <button class="btn btn-primary page-indicator" @click.prevent="step = n-1" :disabled="step < n-1 ? true:false ">{{n}}</button>
                        </div>
                        <hr class="dotted-line px-4 px-md-0" v-if="n != threshold"/>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!completed" class="container my-sm-4 text-center">
      <SignUpOne :bbdata="bbdata" :bbid="bbid" @signUpStep="signUpStep" @setBBID="setBBID" v-if="step == 0"/>
      <SignUpTwo :bbdata="bbdata" :bbid="bbid" @signUpStep="signUpStep" v-else-if="step == 1"/>
      <SignUpThree :bbdata="bbdata" :bbid="bbid" @signUpStep="signUpStep" v-else-if="step == 2"/>
    </div>
    <div v-else class="container my-4 text-center">
        <h1>Meet &amp; Greet Booking Confirmed!</h1>
        <p class="lead">We've sent you an email to confirm your booking!</p>
        <h5>In the meantime follow us on</h5>
        <div class="d-flex justify-content-center">
            <a href="https://www.facebook.com/BarkBuildings/" class="p-3"><img src="https://assets.website-files.com/5ee8f48c3d925237f726b2e1/5f1191085ccb6573467fc457_Facebook.png"  width="24" alt="facebook"/></a>
            <a href="https://www.instagram.com/barkbuildings/" class="p-3"><img src="https://assets.website-files.com/5ee8f48c3d925237f726b2e1/5f1191398cf1f364e6faed3a_Instagram.png" width="24" alt="facebook"/></a>
            <a href="https://www.linkedin.com/company/bark-buildings" class="p-3"><img src="https://assets.website-files.com/5ee8f48c3d925237f726b2e1/5f2b94d87566ee6ec7f22985_linked-in.png" width="24" alt="facebook"/></a>
        </div>
    </div>

  </div>
</template>

<script>

import db from '@/firebase/init'
import SignUpOne from '@/components/signup/One.vue'
import SignUpTwo from '@/components/signup/Two.vue'
import SignUpThree from '@/components/signup/Three.vue'

export default {
  name: 'Home',
  data(){
    return{
      step:0,
      threshold:3,
      completed: false,
      bbid:null,
      bbdata:{
        name:null,
        lastname:null,
        email:null,
        phonenumber:null,
        building:null,
        unit:null,
        pets:[],
        steps:0
      }
    }
  },
  components: {
    SignUpOne,
    SignUpTwo,
    SignUpThree
  },
  created() {
    //do something after creating vue instance
    window.onbeforeunload = function (event) {

        db.collection('users').add(this.bbdata).then((docRef) => {
          console.log(docRef.id);
          this.$emit('signUpStep', 1)
        }).catch(err => {
          alert.log('We could not submit your sign up 😞');
        })

        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = window.event;
        }
        if (event) {
            event.returnValue = message;
        }



        return message;
    };

  },
  methods: {
    setBBID(bbid) {
      console.log(bbid)
      this.bbid = bbid ? bbid:null
    },
    signUpStep(step) {
      this.step += step
      this.bbdata.steps = this.step
      if(this.step>= this.threshold ){
        this.completed = true
      }
    }
  }
}
</script>

<style>
  .page-indicator{
    border-radius:50%;
    width:35px;
    height: 35px;
    padding: 0;
  }
  .dotted-line{
    background-image: linear-gradient(to right, #01A0D9 33%, rgba(255,255,255,0) 0%);
    background-position: bottom;
    background-size: 5px 2px;
    border: none;
    height: 2px;
    background-repeat: repeat-x;
    width: 250px;
  }
  @media only screen and (max-width: 760px) {
    .dotted-line{
      width: auto;
    }
  }
</style>
