<template>
<div>
  <header class="mx-auto" style="max-width:768px;">
    <h2 v-if="title" class="h2">{{title}}</h2>
    <p v-if="subtitle">We take extra care to get to know each dog and personalize their experience. Schedule a 15-minute phone meet and greet below, then our community manager will setup your in-person meeting.</p>
  </header>
  <!-- Calendly inline widget begin -->
  <div id="calendly-inline-widget" style="min-width:320px;height:700px;"></div>
  <!-- :data-url="`https://calendly.com/rachouanrejeb/rad?name=${this.bbdata.name}%20${this.bbdata.lastname}&email=${this.bbdata.email}`" -->

  <form @submit.prevent="signUpUser">
    <div class="field center-align">
      <p v-if="feedback" class="red-text">{{ feedback }}</p>
    </div>
  </form>
</div>
</template>

<script>
import db from '@/firebase/init'

export default {
  name: 'SignUp',
  props: ['bbdata', 'bbid'],
  data() {
    return {
      feedback: null,
      title: 'Schedule your phone Meet & Greet',
      subtitle: 'We take extra care to get to know each dog and personalize their experience. Schedule a 15-minute phone meet and greet below, then our community manager will setup your in-person meeting.'
    }
  },
  mounted() {
    Calendly.initInlineWidget({
      url: 'https://calendly.com/bark-buildings-new-clients/bark-building-phone-meet-and-greet?hide_landing_page_details=1&hide_event_type_details=1',
      parentElement: document.getElementById('calendly-inline-widget'),
      prefill: {
        name: this.bbdata.name + " " + this.bbdata.lastname,
        email: this.bbdata.email,
        phoneNumber: this.bbdata.phonenumber,
        customAnswers: {
          a1: `Building:${this.bbdata.building} \nUnit:${this.bbdata.unit} \nPhone Number:${this.bbdata.phonenumber} \npets:${JSON.stringify(this.bbdata.pets, null,1).replace(/[\[\]']+/g,'').replace(/[\{\}']+/g,'').replace(/['"]+/g, '')}`
        }
      },
      utm: {}
    });
    let self = this
    window.addEventListener(
      'message',
      function(e) {
        if (self.isCalendlyEvent(e)) {
          if (e.data.event == 'calendly.event_scheduled') {
            self.signUpUser()

          }
        }
      }
    )
  },
  methods: {
    signUpUser() {
      let self = this
      if (this.bbdata.name) {

        let stepdata = {
          name: this.bbdata.name,
          lastname: this.bbdata.lastname,
          email: this.bbdata.email,
          phonenumber: this.bbdata.phonenumber,
          building: this.bbdata.building,
          unit: this.bbdata.unit,
          pets: JSON.stringify(this.bbdata.pets).replace(/[\[\]']+/g, '').replace(/[\{\}']+/g, '').replace(/['"]+/g, ''),
          steps: this.bbdata.steps,
          created: Date.now()
        }

        db.collection('stepthree').add(stepdata).then((docRef) => {
          this.$emit('signUpStep', 1)
        }).catch(err => {
          alert.log('We could not submit your sign up 😞');
        })

      } else {
        this.feedback = 'You must enter your full name'
      }
    },
    deleteIng(ing) {
      this.pets = this.pets.filter(ingredient => {
        return ingredient != ing
      })
    },
    isCalendlyEvent(e) {
      return e.data.event &&
        e.data.event.indexOf('calendly') === 0;
    }
  }
}
</script>

<style>
.add-smoothie {
  margin-top: 60px;
  padding: 20px;
  max-width: 500px;
}

.add-smoothie h2 {
  font-size: 2em;
  margin: 20px auto;
}

.add-smoothie .field {
  margin: 20px auto;
  position: relative;
}

.ingredients li {
  display: inline-block;
}

.delete {
  opacity: .5;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 15px;
}
</style>
