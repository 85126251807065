import firebase from 'firebase'
import firestore from 'firebase/firestore'

// Your web app's Firebase configuration
var config = {
  apiKey: "AIzaSyDWYtbi2v-ihFPcaT_kZ30u3ODIP2wPfug",
  authDomain: "barkbuildings.firebaseapp.com",
  databaseURL: "https://barkbuildings.firebaseio.com",
  projectId: "barkbuildings",
  storageBucket: "barkbuildings.appspot.com",
  messagingSenderId: "741146474771",
  appId: "1:741146474771:web:13d53a4f1bfeeec08d42e6",
  measurementId: "G-VRTHRTPHEH"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(config);

//export firestore database
export default firebaseApp.firestore()
