<template>
  <div class="mx-auto" style="max-width:768px;">
    <h1 class="h1 mb-4 text-center mx-auto" style="max-width:500px;">Get ready to experience Bark Buildings</h1>

    <form @submit.prevent @change="checkForm">
      <div class="row text-left">
        <div class="form-group col-12 col-sm">
            <label for="firstname">First Name<span class="text-muted">*</span></label>
          <input type="text" name="name" v-model="bbdata.name" required="required" class="form-control" id="firstname" aria-describedby="firstname" placeholder="First Name">
        </div>
        <div class="form-group col-12 col-sm">
          <label for="lastname">Last Name<span class="text-muted">*</span></label>
          <input type="text" name="lastname" v-model="bbdata.lastname" required="required" class="form-control" id="lastname" aria-describedby="lastname" placeholder="Last Name">
        </div>
      </div>
      <div class="row text-left">
        <div class="form-group col-12 col-sm">
          <label for="email">Email Address<span class="text-muted">*</span></label>
          <input type="email" name="email" v-model="bbdata.email" required="required" class="form-control" id="email" aria-describedby="email" placeholder="Email Address">
        </div>
        <div class="form-group col-12 col-sm">
          <label for="number">Phone Number</label>
          <input type="text" name="number" v-model="bbdata.phonenumber" required="required" class="form-control" id="number" aria-describedby="number" placeholder="Phone Number">
        </div>
      </div>
      <div class="row text-left">
        <div class="form-group col-12 col-sm">
          <label for="building">Select Your Building<span class="text-muted">*</span></label>
          <select class="form-control" v-model="bbdata.building"  id="building" required="required" placeholder="Select Your Building...">
            <option value="Not Selected" selected="selected">Select Your Building ...</option>
            <option v-for="building in buildings" :key="building.key" :value="building">{{ building}}</option>
          </select>
        </div>
        <div class="form-group col-12 col-sm">
          <label for="building">Unit<span class="text-muted">*</span></label>
          <input type="text" name="unit" v-model="bbdata.unit" required="required" class="form-control" id="unit" aria-describedby="unit" placeholder="Unit">
        </div>
      </div>
      <div class="text-left">
        <h3 class="h3 text-left">Pets<span class="text-muted">*</span></h3>
        <p class="">Add at least one dog or cat</p>
        <div class="row">

          <div class="form-group col-12 col-sm">
            <div v-for="(pet, index) in bbdata.pets" :key="index" v-if="pet.type == 'Dog' " class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">{{pet.type}}</span>
              </div>
              <input type="text" class="form-control" placeholder="Name" aria-label="Name" aria-describedby="button-addon2" v-model="bbdata.pets[index].name" required="required">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"  @click.prevent="deletePet(pet)">Delete</button>
              </div>
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Dog</span>
              </div>
              <input type="text" class="form-control" @keydown.tab.prevent="addPet('Dog',dog)" v-model="dog">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" id="button-addon2"  @click.prevent="addPet('Dog',dog)">Add</button>
              </div>

            </div>
          </div>

          <div class="form-group col-12 col-sm">
            <div v-for="(pet, index) in bbdata.pets" :key="index" v-if="pet.type == 'Cat' " class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">{{pet.type}}</span>
              </div>
              <input type="text" class="form-control" placeholder="Name" aria-label="Name" aria-describedby="button-addon2" v-model="bbdata.pets[index].name" required="required">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" id="button-addon2"  @click.prevent="deletePet(pet)">Delete</button>
              </div>
            </div>

            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text">Cat</span>
              </div>
              <input type="text" class="form-control" @keydown.tab.prevent="addPet('Cat',cat)" v-model="cat">
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" id="button-addon2"  @click.prevent="addPet('Cat',cat)">Add</button>
              </div>
            </div>
          </div>

        </div>
      </div>
      <p v-if="feedback" class="alert alert-danger">{{ feedback }}</p>
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="terms">
        <label class="form-check-label" for="exampleCheck1">I agree to the <a href="https://barkbuildings.com/pages/terms-conditions" target="_blank">terms of service</a> and <a href="https://barkbuildings.com/pages/privacy-policy" target="_blank">privacy policy</a>.</label>
      </div>
      <button type="submit" class="btn btn-primary mx-auto" :class="{ 'disabled':!terms, 'disabled':!filled }" @click.prevent="nextForm" :disabled="!terms || !filled ? true : false">Count me in</button>
    </form>

  </div>
</template>

<script>
import db from '@/firebase/init'

export default {
  name:'SignUp',
  props: ['bbdata','bbid'],
  data(){
    return{
      feedback: null,
      dog:null,
      cat:null,
      terms:false,
      filled:false,
      pet:{
        type:null,
        name:null,
        breed:null,
        sex:null,
        neutered:null,
        age:null,
        birthday: null
      },
      buildings:[
        'Cast Iron Lofts I', 
        'Cast Iron Lofts II', 
        '50 Columbus', 
        '70 Columbus', 
        '90 Columbus', 
        'Marbella', 
        'M2', 
        'Monaco', 
        'Soho Lofts', 
        'Enclave', 
        '100 House',
        'One Ten',
        '18 Park', 
        '485 Marin', 
        '225 Grand', 
        '235 Grand', 
        'Grove Pointe', 
        'Journal Squared', 
        'Journal Squared Tower 2',
        'Vantage', 
        '1 Park View Avenue', 
        '333 Grand', 
        '50 Regent', 
        'The Regent (30 Regent)', 
        'The Zenith (9 Regent)', 
        'The Junction', 
        'The Sutton', 
        'Liberty Towers', 
        'Modera Lofts', 
        '401 BLVD', 
        '425 BLVD', 
        '475 BLVD'
      ]
    }
  },
  methods: {
    addPet(type,name){
      this.pet.type = type
      if(name){
        this.pet.name = name
        this.bbdata.pets.push(this.pet)
        this.pet = {
          type:null,
          name:null,
          breed:null,
          sex:null,
          neutered:null,
          age:null,
          birthday: null
        }
        this.dog = null,
        this.cat = null,
        this.feedback = null
        this.checkForm()
      } else {
        this.feedback = 'You must add a pet name'
      }
    },
    checkForm(){
      this.filled = this.bbdata.name && this.bbdata.lastname && this.bbdata.email && this.bbdata.building && this.bbdata.unit && this.bbdata.pets.length > 0 ? true : false
    },
    nextForm(){
      if(!this.bbdata.name || !this.bbdata.lastname || !this.bbdata.email || !this.bbdata.building || !this.bbdata.unit){
        this.feedback = 'Please fill out all required fields'
      }else if(this.bbdata.pets.length == 0) {
        this.feedback = 'Please add at least 1 pet'
      }else {

        let stepdata = {
          name:this.bbdata.name,
          lastname:this.bbdata.lastname,
          email:this.bbdata.email,
          phonenumber:this.bbdata.phonenumber,
          building:this.bbdata.building,
          unit:this.bbdata.unit,
          pets:JSON.stringify(this.bbdata.pets).replace(/[\[\]']+/g,'').replace(/[\{\}']+/g,'').replace(/['"]+/g, ''),
          steps:this.bbdata.steps,
          created: Date.now()
        }


        db.collection('stepone').add(stepdata).then((docRef) => {
          this.$emit('signUpStep', 1)
        }).catch(err => {
          alert.log('We could not submit your sign up 😞');
        })


      }
    },
    deletePet(pet){
      this.bbdata.pets = this.bbdata.pets.filter(curpet =>{
        return curpet != pet
      })
    }
  }
}
</script>
