<template>
  <div class="mx-auto" style="max-width:768px;">
    <h2 class="center-align black-text mb-4 mb-md-5">Tell us more</h2>

    <div class="accordion mb-4" id="petAccordion">
      <div class="card" v-for="(pet, index) in bbdata.pets">
        <div class="card-header bg-white" id="headingOne">
          <h2 class="h3 mb-0">
            <button class="btn bg-white btn-lg btn-block text-left" type="button" data-toggle="collapse" :data-target="'#collapse'+pet.name" aria-expanded="true" :aria-controls="'collapse'+pet.name">
              Tell us more about <span class="text-primary">{{pet.name}}</span>
            </button>
          </h2>
        </div>

        <div :id="'collapse'+pet.name" class="collapse" :class="{ 'show': index === 0 }" aria-labelledby="headingOne" data-parent="#petAccordion">
          <div class="card-body">
            <div class="row text-left">
              <div class="form-group col-12 col-sm">
                <label for="sex">Sex</label>
                <select class="form-control" v-model="pet.sex" id="sex" placeholder="Sex">
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm">
                <label for="breed">Breed</label>
                <input type="text" name="breed" v-model="pet.breed" class="form-control" id="breed" aria-describedby="breed" placeholder="Breed">
              </div>
            </div>
            <div class="row text-left">
              <div class="form-group col-12 col-sm-3">
                <label for="neutered">Spayed/neutered?</label>
                <select class="form-control" v-model="pet.neutered"  id="neutered" placeholder="Spayed/neutered?">
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-3">
                <label for="age">Age</label>
                <input type="number" name="petage" v-model="pet.age" class="form-control" id="age" aria-describedby="email" placeholder="Age">
              </div>
              <div class="form-group col-12 col-sm-6">
                <label for="date">Birthday or Got Date</label>
                <input type="date" name="date" v-model="pet.birthday" class="form-control" id="date" aria-describedby="number" placeholder="Birthday or Got Date">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent>
      <div class="field center-align">
        <button class="btn btn-primary" name="button" @click.prevent="nextForm">Continue</button>
      </div>
    </form>
  </div>
</template>

<script>
import db from '@/firebase/init'

export default {
  name:'SignUp',
  props: ['bbdata','bbid'],
  data(){
    return{
      pet:null,
      feedback: null
    }
  },
  methods: {
    nextForm(){

      let stepdata = {
        name:this.bbdata.name,
        lastname:this.bbdata.lastname,
        email:this.bbdata.email,
        phonenumber:this.bbdata.phonenumber,
        building:this.bbdata.building,
        unit:this.bbdata.unit,
        pets:JSON.stringify(this.bbdata.pets).replace(/[\[\]']+/g,'').replace(/[\{\}']+/g,'').replace(/['"]+/g, ''),
        steps:this.bbdata.steps,
        created: Date.now()
      }

      db.collection('steptwo').add(stepdata).then((docRef) => {
        console.log('Completed');
        this.$emit('signUpStep', 1)
      }).catch(err => {
        alert.log('We could not submit your sign up 😞');
      })
    },
    deletePet(pet){
      this.bbdata.pets = this.bbdata.pets.filter(curpet =>{
        return curpet != pet
      })
    }
  }
}
</script>

<style>
  .add-smoothie{
    margin-top: 60px;
    padding: 20px;
    max-width: 500px;
  }
  .add-smoothie h2{
    font-size: 2em;
    margin: 20px auto;
  }
  .add-smoothie .field{
    margin: 20px auto;
    position: relative;
  }
  .ingredients li{
    display: inline-block;
  }
  .delete{
    opacity: .5;
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 15px;
  }

</style>
